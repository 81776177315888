<template>
  <div class="contact-us">
    <!-- <div class="contact-us-bg" /> -->
    <div class="header">
      <div class="header-img">
        <div style="display: flex;" @click="jumpToHome">
          <div>
            <img style="width:0.75rem;height:0.75rem;object-fit:cover;" src="../../static/images/home-b-t.png" alt="">
          </div>
          <div style="margin-top: 0.1rem;">
            <img style="width:1.47rem;height:0.49rem;object-fit:cover;vertical-align: super;margin-left: 0.1rem;" src="../../static/images/home-b-a.png" alt="">
          </div>
        </div>
        <div class="right" @click="jumpToHome1">
          <img style="width:0.68rem;height:0.57rem;object-fit:cover;" src="../../static/images/home-s-b.png" alt="">
        </div>
      </div>
    </div>
    <div class="contact-con">
      <!-- 加入我们 -->
      <div class="join-us">
        <div class="join-us-title">{{$t('m.join_name')}}</div>
        <div class="decoration" />
      </div>
      <!-- 列表页 -->
      <div class="list-info-box">
        <div v-for="(item, index) in jobList"  :key="item.id" class="list-item-box">
          <div class="list-item-header" :class="{ active: openArr[index]['isOpen'] }">
            <!-- 装饰 -->
            <div class="decoration-box">
              <div class="it1"></div>
              <div class="it2"></div>
              <div class="it3"></div>
            </div>
            <!-- 展开按钮 -->
            <div class="open-box">
              <div v-show="!openArr[index]['isOpen']"  @click="open(index, true)">展开 <img style="width: .2rem;" src="../../static/images/arrow-bottom.png" alt=""></div>
              <div v-show="openArr[index]['isOpen']" @click="open(index, false)">收起 <img style="width: .2rem;" src="../../static/images/arrow-top.png" alt=""></div>
            </div>
            <p class="post">{{ item.name }}</p>
            <p class="bot">
              <span class="salary">{{ item.min + '-' + item.max }}k</span>
              <span class="exp">工作经验：{{ item.yearName }}</span>
            </p>
          </div>
          <!-- 展开内容 -->
          <div class="open-con" v-show="openArr[index]['isOpen']">
            <div v-html="item.content" class="item">
            </div>
          </div>
        </div>
      </div>
      <!-- 联系我们 -->
      <div class="join-us">
        <div class="join-us-title">{{$t('m.home.join_3')}}</div>
        <div class="decoration" />
      </div>
      <div class="call-us-box">
        <div id="mapContainer"></div>
        <p><img src="../../static/images/phone1.png" alt=""> <a href="tel:400-0428-468" style="color: #999;">400-0428-468</a></p>
        <p><img src="../../static/images/mail1.png" alt="">aebj@air-bridge.cn</p>
        <p><img src="../../static/images/location1.png" alt="">重庆渝北区两江新区互联网产业园二期6栋18楼</p>
      </div>
    </div>

    <!-- <div class="mask-box" v-if="isLoading">
      <img src="../../static/images/loading2.gif" alt="">
    </div> -->
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import { getList } from '@/api/home'
import { recruit_page } from '@/api/contactUs'
export default {
  data() {
    return {
      map: null,
      openArr: [],
      jobList: [],
      isLoading: true
    }
  },
  mounted() {
    this.initMap();
    // 调用接口
    getList({
      infoType: "info_project",
      pageNum: 1,
      pageSize: 5,
      total: 0
    }),
    this.getList()
  },
  methods: {
    jumpToHome() {
        this.$router.push('/')
      },
      jumpToHome1() {
        this.$router.push('/home2')
      }, 
    getList() {
      recruit_page({pageNum: 1, pageSize: 20}).then(res => {
        this.isLoading = false
        if(res.code === 0) {
          this.jobList = res.data.list
          if (this.jobList && this.jobList.length > 0) {
            for (let i = 0; i < this.jobList.length; i++) {
              this.openArr.push({
                index: i,
                isOpen: false
              })
            }
          }
        }
      })
    },
    open(index, flag) {
      for (let i = 0; i < this.openArr.length; i++) {
          this.openArr[i]['isOpen'] = false
      }
      this.openArr[index]['isOpen'] = flag
      console.log(this.openArr);
    },
    initMap() {
      AMapLoader.load({
        key: "4e20c682d835895bfb6dc8c222326316",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("mapContainer", {  //设置地图容器id
          viewMode: "3D",    //是否为3D地图模式
          zoom: 17,           //初始化地图级别
          center: [106.496525, 29.632101], //初始化地图中心点位置
        });
        var marker = new AMap.Marker({
          position: new AMap.LngLat(106.496525, 29.632101),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: '重庆'
        });
        this.map.add(marker)
      }).catch(e => {
        console.log(e);
      })
    },
  }
}
</script>

<style scoped>
.header {
  position: relative;
  padding: 0.36rem 0.4rem 0 0.4rem;
  box-sizing: border-box;
  width: 100%;
  height: 6.05rem;
  background: url("../../static/images/us.png");
  background-size: 100% 100%;
}
.header-img {
  display: flex;
  justify-content: space-between;
}
.header-text {
  position: absolute;
  margin-left: -4rem;
  top: 50%;
  left: 50%;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 3px;
}
.contact-us {
  background: RGBA(249, 249, 249, 1);
  padding-bottom: 0.56rem;
}

.contact-con {
  padding: 0 0.4rem;
}
.join-us {
  position: relative;
  height: 0.8rem;
  margin: 0.97rem 0;
}
.join-us-title {
  position: absolute;
  width: 4rem;
  top: 0;
  left: 42%;
  margin-left: -1.28rem;
  letter-spacing: 0.05rem;
  text-align: center;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #27201b;
  z-index: 2;
}
.join-us .decoration {
  position: absolute;
  width: 4.34rem;
  height: 0.3rem;
  background: rgba(255, 171, 10, 1);
  top: 0.4rem;
  left: 42%;
  margin-left: -1.42rem;
  z-index: 1;
}
/* 列表页 */
.list-item-box {
  background: #ffffff;
  border-top-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  margin-bottom: 0.81rem;
}
.list-item-header {
  position: relative;
  height: 1.83rem;
  border-top-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  background: #fff;
  overflow: hidden;
}
.list-item-header.active {
  background: #ffab0a;
}
.list-item-header .post {
  margin-top: 0.54rem;
  margin-left: 0.28rem;
  font-size: 0.43rem;
  color: rgba(102, 102, 102, 1);
}
.list-item-header .bot {
  font-size: 0.32rem;
  margin-top: 0.18rem;
  margin-left: 0.28rem;
  color: rgba(153, 153, 153, 1);
}
.list-item-header.active .post {
  color: #ffffff;
}
.list-item-header.active .bot {
  color: #ffffff;
}
.list-item-header .exp {
  margin-left: 0.43rem;
}
.decoration-box {
  position: absolute;
  width: 0.5rem;
  top: 0;
  left: 0.93rem;
}
.decoration-box > div {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 171, 10, 1);
  width: 0.04rem;
}
.list-item-header.active .decoration-box > div {
  background: #ffffff;
}
.decoration-box .it1 {
  height: 0.21rem;
}
.decoration-box .it2 {
  height: 0.41rem;
  left: 0.07rem;
}
.decoration-box .it3 {
  height: 0.34rem;
  left: 0.14rem;
}
.list-item-header .open-box {
  position: absolute;
  width: 1rem;
  height: 0.4rem;
  line-height: 0.4rem;
  top: 50%;
  margin-top: -0.2rem;
  right: 0.15rem;
  font-size: 0.32rem;
  color: rgba(102, 102, 102, 1);
}
.list-item-header.active .open-box {
  color: #ffffff;
}
.open-con {
  padding-bottom: 0.68rem;
  margin-top: -0.4rem;
  padding-top: 0.4rem;
}
.open-con .item {
  padding: 0.6rem 0.19rem 0 0.19rem;
  color: #666666;
  font-size: 0.37rem;
  line-height: 0.74rem;
}
.open-con .p-title {
  font-size: 0.43rem;
  color: #27201b;
  margin-bottom: 0.65rem;
}
.open-con .des {
  padding-left: 0.3rem;
}
.call-us-box {
  padding-bottom: 0.64rem;
  background: #ffffff;
  border: 1px solid #ffab0a;
  border-top-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  overflow: hidden;
}
.call-us-box p {
  font-size: 0.37rem;
  margin-top: 0.6rem;
  color: #999999;
  padding-left: 0.3rem;
}
.call-us-box p img {
  width: 0.35rem;
  margin-right: 0.3rem;
}
#mapContainer {
  width: 100%;
  height: 8rem;
}
.mask-box {
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(0,0,0,.4);
}
</style>